import React from 'react'
import { Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { Text } from '@/components/Text'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useContentInProp } from '@/hooks/useContentInProp'
import { Right } from '@/layouts/styled'
import { SideNavigationLink } from '@/components/SideNavigationLink'

const ModulesPage: React.FC = () => {
  const { t } = useTranslation()
  const inProp = useContentInProp()

  const content = {
    pretitle: t(
      'our-platform.modules_super',
      'fill copy key: our-platform.modules_super',
    ),
    title: t(
      'our-platform.modules_title',
      'fill copy key: our-platform.modules_title',
    ),
    text: t(
      'our-platform.modules_body',
      'fill copy key: our-platform.modules_body',
    ),

    links: [
      {
        link: t('our-platform.modules_cta_right'),
        url: '/our-platform/all-modules',
        variant: 'mdDown',
      },
      {
        link: t(
          'our-platform.modules_cta',
          'fill copy key: our-platform.modules_cta',
        ),
        url: '/our-platform/partners',
        variant: 'all',
      },
    ],
  }

  return (
    <>
      <Right>
        {inProp && (
          <SideNavigationLink
            variant="light"
            position="right"
            delay={1.1}
            time={1}
            url="/our-platform/all-modules"
            text={t('our-platform.modules_cta_right')}
          />
        )}
      </Right>
      <Page
        content={
          <PageWrapper>
            <WithSlideShowCheck
              mobileContent={[<Text isMobile {...content} />]}
            >
              <Box row>
                <Box col={0.4}>
                  <Text {...content} />
                </Box>
                <Box col={0.6} data-url />
              </Box>
            </WithSlideShowCheck>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
    </>
  )
}

export default ModulesPage
